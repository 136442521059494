/*------------------------------------------------
// File Name:tGY_Base_Config.js
// File Description:tGY_Base_Config API方法js接口
// Author:zxl
// Create Time:2024/03/29 11:44:50
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _baseConfig: "/tGY_Base_Config"
}

export default api


//-------------------创建整项目配置表对象-------------------
export function CreatetGY_Base_ConfigEntity(parameter) {
    return axios({
        url: api._baseConfig + "/CreatetGY_Base_ConfigEntity",
        method: 'post',
        data: parameter
    })
}

/**获取整项目配置表列表 */
export function GettGY_Base_ConfigList(parameter) {
    return axios({
        url: api._baseConfig + "/GettGY_Base_ConfigList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取整项目配置表列表---------------
export function GettGY_Base_ConfigListByPage(parameter) {
    return axios({
        url: api._baseConfig + "/GettGY_Base_ConfigListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取整项目配置表对象---------------
export function GettGY_Base_ConfigEntity(parameter) {
    return axios({
        url: api._baseConfig + "/GettGY_Base_ConfigEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存整项目配置表对象---------------
export function UpdatetGY_Base_ConfigEntity(parameter) {
    return axios({
        url: api._baseConfig+ "/UpdatetGY_Base_ConfigEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉整项目配置表对象---------------
export function DeletetGY_Base_ConfigEntity(parameter) {
    return axios({
        url: api._baseConfig + "/DeletetGY_Base_ConfigEntity",
        method: 'post',
        data: parameter
    })
}
