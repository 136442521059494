<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="患者名称/患者ID/交易流水号"
          color="primary"
        />
        <q-input
          outlined
          class="col-2"
          dense
          v-model="STATE_ID"
          label="业务逻辑ID"
          color="primary"
        />
        <q-card flat  >
          <div class="row q-px-sm" style="width: 460px;">
            <div
              class="col-1"
              style="display: flex;align-items: center;width: 70px;"
            >
              查询时间:
            </div>
            <div style="padding-top: 2px;">
              <vxe-input
                v-model="BenDate"
                placeholder="查询开始时间"
                type="datetime"
                transfer
              ></vxe-input>
            
            </div>
            <div style="display: flex;align-items: center;width: 15px;">
             —
            </div>
            <div class="col-4" style="padding-top: 2px;">
             
              <vxe-input
                v-model="EndDate"
                placeholder="查询结束时间"
                type="datetime"
                transfer
              ></vxe-input>
            </div>
          </div>
        </q-card>
        <!-- <q-input
          dense
          outlined
          type="date"
          label="查询开始日期"
          stack-label
        >
        <template v-slot:prepend>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date v-model="BenDate" mask="YYYY-MM-DD HH:mm">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>

      <template v-slot:append>
        <q-icon name="access_time" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-time v-model="BenDate" mask="YYYY-MM-DD HH:mm" format24h>
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-time>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input> -->
        <!-- <q-input
          dense
          outlined
          type="date"
          v-model="EndDate"
          label="查询结束日期"
          stack-label
        /> -->
        <!-- <q-select
          map-options
          emit-value
          outlined
          dense
          style="width:120px;"
          v-model="PayState"
          color="primary"
          label="支付状态"
          :options="[
            { label: '全部', value: '' },
            { label: '待支付', value: '0' },
            { label: '已支付', value: '1' },
            { label: '已退款', value: '2' },
            { label: '有效支付', value: '3' },
          ]"
        /> -->
        <q-card flat bordered>
          <div class="row q-px-sm">
            <div
              class="col-md-auto "
              style="display: flex;align-items: center;color: red;"
            >
              支付状态:
            </div>
            <div class="col-md-auto">
              <q-option-group
                v-model="PayStateInfo"
                :options="PayStateData"
                type="checkbox"
                color="primary"
                inline
              />
            </div>
          </div>
        </q-card>
        <q-card flat bordered>
          <div class="row q-px-sm">
            <div
              class="col-md-auto "
              style="display: flex;align-items: center;color: red;"
            >
              支付类型:
            </div>
            <div class="col-md-auto">
              <q-option-group
                v-model="PayTypeInfo"
                :options="PayTypeData"
                type="checkbox"
                color="primary"
                inline
              />
            </div>
          </div>
        </q-card>
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="HsmUnionpayListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="HsmUnionpayListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="HsmUnionpayListLoading" @click="onExportData">导出</q-btn> -->
        <!-- <q-btn color="primary" style="height: 38px" icon="note_add" :loading="HsmUnionpayListLoading" @click="onAdd">新增</q-btn> -->
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 160"
        :loading="HsmUnionpayListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            status="primary"
            :loading="HsmUnionpayListLoading"
            @click="onselect(row)"
            >查询订单状态</vxe-button
          >
          <vxe-button
            flat
            status="danger"
            :loading="HsmUnionpayListLoading"
            @click="onSetBackState(row)"
            >退款</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import * as baseConfig from "@/api/gy/base/baseConfig";

export default {
  components: {},
  computed: {
    ...mapState("HsmUnionpay", [
      "HsmUnionpayList",
      "HsmUnionpayListLoading",
      "HsmUnionpayEntity",
      "HsmUnionpayShowDlg",
    ]),

    hsmUnionpayshowDlg: {
      get() {
        return this.HsmUnionpayShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("HsmUnionpay/setHsmUnionpayShowDlg", val);
      },
    },
  },
  data() {
    return {
      PayTypeData: [],
      PayTypeInfo: [],
      PayStateData: [],
      PayStateInfo: [],
      FilterString: "",
      STATE_ID: "",
      BenDate: XEUtils.toDateString(new Date(), "yyyy-MM-dd")+" 00:00:00",
      EndDate: XEUtils.toDateString(new Date(), "yyyy-MM-dd")+" 23:59:59",
      PayState: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000, 10000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          //  {
          //   field: "UNIONPAY_ID",
          //   title: "主键",
          //   sortable: true,
          //   align: "left",
          //   width: 160,
          // },
          {
            field: "HospName",
            title: "机构名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "PAYRECORDID",
            title: "交易流水号",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "PATNID",
            title: "患者ID",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "PATNNAME",
            title: "患者姓名",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "PAYAMT",
            title: "缴费金额",
            sortable: true,
            align: "right",
            width: 100,
          },
          {
            field: "PAY_REALAMT",
            title: "实际金额",
            sortable: true,
            align: "right",
            width: 100,
          },
          {
            field: "PAYSTATE_NAME",
            title: "支付状态",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "TYPES_NAME",
            title: "支付类型",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "PAYTIME",
            title: "支付时间",
            sortable: true,
            align: "center",
            width: 140,
          },
          {
            field: "PAYTYPE_NAME",
            title: "缴费类型",
            sortable: true,
            align: "left",
            width: 160,
          },

          {
            field: "CDATE",
            title: "创建时间",
            sortable: true,
            align: "center",
            width: 160,
          },

          {
            field: "BILLNO",
            title: "三方账单号",
            sortable: true,
            align: "left",
            width: 200,
          },

          {
            field: "PAYRECORDSOURCE",
            title: "支付业务类型",
            sortable: true,
            align: "left",
            width: 110,
          },
          {
            field: "UNIONPAY_STATE_NAME",
            title: "银联交互状态",
            sortable: true,
            align: "center",
            width: 110,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.HsmUnionpayList,
        data: null,
      },
    };
  },
  mounted() {
    this.onGetPayType();
    this.onGetPayState();
    this.onLoadData();
  },
  methods: {
    ...mapActions("HsmUnionpay", [
      "actGetHsmUnionpayListByPage",
      "actGetPayState",
      "actSetBackState",
    ]),
    onLoadData() {
      if (!this.BenDate || !this.EndDate) {
        dlg.alert("查询时间不能为空");
        return;
      }
      this.actGetHsmUnionpayListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        STATE_ID: this.STATE_ID,
        BenDate: this.BenDate,
        EndDate: this.EndDate,
        PayState: this.PayState,
        PayTypeInfo: this.PayTypeInfo,
        PayStateInfo: this.PayStateInfo,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit("HsmUnionpay/setHsmUnionpayListLoading", false);
          //this.HsmUnionpayListLoading = false;
        });
    },
    onGetPayType() {
      baseConfig
        .GettGY_Base_ConfigList({
          HospID: -1,
          ConfigType: "PayType",
        })
        .then((res) => {
          if (res.Code == 0) {
            //this.PayTypeInfo = res.OtherData.info;
            this.PayTypeData = res.OtherData.data;
          } else {
            dlg.alert(res.Message);
          }
        });
    },
    onGetPayState() {
      baseConfig
        .GettGY_Base_ConfigList({
          HospID: -1,
          ConfigType: "PayState",
        })
        .then((res) => {
          if (res.Code == 0) {
            //this.PayStateInfo = res.OtherData.info;
            this.PayStateData = res.OtherData.data;
          } else {
            dlg.alert(res.Message);
          }
        });
    },
    onselect(val) {
      this.actGetPayState({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: val,
        // UNIONPAY_ID: val.UNIONPAY_ID,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    onSetBackState(val) {
      this.actSetBackState({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: val,
        // HospID:val.HOSPID,
        // UNIONPAY_ID: val.UNIONPAY_ID,
        // PAY_REALAMT:val.PAY_REALAMT
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },

    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
